import React, { useRef, useState } from 'react';
import useOnClickOutside from '../../hook/useOnClickOutside';
import * as precoService from '../../services/calculs/applyPreconisation';
import { currencyFormat, parseCurrency, percentFormat } from '../../services/tools/TypeHelper';
import { CartesianGrid, Line, LineChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useRecoilValue } from 'recoil';
import { ticketStateAtom } from '../../services/Recoil/Atom/Themes.atom';
import ProjectionFinanciereCustomTooltip from './CustomTooltip';

// Style
import './ModaleProjectionFinanciere.scss';

// Icons
import { ReactComponent as IconClose } from '../../assets/icons/icon-close.svg';
import { ReactComponent as IconGraph } from '../../assets/icons/simulator/icon-graph.svg';
import { ReactComponent as IconTable } from '../../assets/icons/simulator/icon-table.svg';

type TModalFooter = Array<{
    title: string;
    colspan?: number | null;
}>;

interface Props {
    topTitle?: string;
    closeTop?: boolean;
    title: string;
    close: () => void;
    content: precoService.ProjectionFinanciere;
}

type TabsProps = {
    selectedTab: number;
    setSelectedTab: (tab: number) => void;
};

type CrossingProps = {
    annee: number;
    gainCumule: number;
};

const Tabs = ({ selectedTab, setSelectedTab }: TabsProps) => {
    const handleClick = (tab: number) => {
        setSelectedTab(tab);
    };

    return (
        <div className="tab-container">
            <div className="btn-group">
                <button type="button" className="btn btn-tab-icon" onClick={() => handleClick(0)} data-active={selectedTab === 0}>
                    <IconTable height={24} width={24} fill="#4a5e6a" />
                </button>
                <button type="button" className="btn btn-tab-icon" onClick={() => handleClick(1)} data-active={selectedTab === 1}>
                    <IconGraph height={24} width={24} fill="#4a5e6a" />
                </button>
            </div>
        </div>
    );
};

const ModaleProjectionFinanciere: React.FC<Props> = ({ topTitle, title, close, closeTop, content }) => {
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const ref = useRef(null);
    const ticket = useRecoilValue(ticketStateAtom);

    const annuel = content?.projectionsAnnuelles ?? new Array<precoService.ProjectionFinanciereAnnuelle>();

    // est-ce qu'on affiche la colonne auto conso ?
    const showAutoconso = annuel.some((e) => {
        return e.autoconso !== 0;
    });
    // est-ce qu'on affiche la colonne autoConsoRatio
    const showAutoconsoRatio = showAutoconso;
    // est-ce qu'on affiche la colonne revente
    const showRevente = annuel.some((e) => {
        return e.revente !== 0;
    });

    //construit les titres de colonnes.
    const titles = new Array<string>();
    titles.push('Année');
    if (showAutoconsoRatio) titles.push('% Autoconso');
    if (showAutoconso) titles.push('Autoconso');
    if (showRevente) titles.push('Revente');
    titles.push('Economie (hors PV)');
    titles.push('Total');
    titles.push('Rendement');

    // construit les footers
    const contentFooter: TModalFooter = [
        {
            title: 'Sur 25 ans',
            colspan: titles.length - 3,
        },
        {
            title: currencyFormat(content.economieHorsPV.prix()),
        },
        {
            title: currencyFormat(content.economiePV.total + content.economieHorsPV.prix()),
        },
        {
            title: percentFormat(content.rendement),
        },
    ];

    const handleClickOutsite = () => {
        close();
    };

    useOnClickOutside(ref, handleClickOutsite);

    const prepareChartData = (annuel: precoService.ProjectionFinanciereAnnuelle[]) => {
        let cumulativeTotal = 0;
        // Do not touch this line, or it will chier des bulles
        const firstElement = { annee: -1, total: 0 };
        return [firstElement, ...annuel].map((element) => {
            cumulativeTotal += element.total;
            return {
                annee: element.annee + 1,
                gainCumule: cumulativeTotal,
            };
        });
    };

    const findCrossings = (data: CrossingProps[], installationCost: number) => {
        const crossings = [];

        for (let i = 1; i < data.length; i++) {
            const prev = data[i - 1];
            const current = data[i];

            const prevDiff = prev.gainCumule - installationCost;
            const currentDiff = current.gainCumule - installationCost;

            if (prevDiff * currentDiff < 0) {
                const ratio = prevDiff / (prevDiff - currentDiff);
                const crossingAnnee = prev.annee + ratio * (current.annee - prev.annee);

                crossings.push({
                    annee: crossingAnnee,
                });
            }
        }

        return crossings;
    };

    const crossings = findCrossings(prepareChartData(annuel), parseCurrency(ticket.reste_a_charge));
    const total = parseCurrency(ticket.reste_a_charge);

    const startYear = Math.floor(crossings[0].annee);
    const endYear = startYear + 1;

    const handleSuffixYear = (year: number) => {
        if (year === 1) {
            return year + ' ère';
        }

        return year + ' ème';
    };

    return (
        <div className="modal modal-custom modal-finance" tabIndex={0} role="dialog" aria-labelledby={title}>
            <div className="modal-dialog" role="document">
                <div className="modal-content" ref={ref}>
                    {topTitle && <p className="modal-top-title">{topTitle}</p>}
                    {closeTop && (
                        <button type="button" className="btn btn-modal-close" onClick={handleClickOutsite}>
                            <IconClose fill="#FD5C21" />
                        </button>
                    )}
                    <div className="modal-header mb-5">
                        <h2 className="modal-title">
                            {title}
                            <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                        </h2>
                    </div>

                    <div className="modal-body">
                        {selectedTab === 0 && (
                            <table className="table table-modal">
                                <thead>
                                    <tr>
                                        {titles.map((title: string, index: number) => (
                                            <th key={index}>{title}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {annuel?.map((element, index: number) => (
                                        <tr key={index}>
                                            <td>{element.annee + 1}</td>
                                            {showAutoconsoRatio && <td>{percentFormat(element.tauxAutoconso)}</td>}
                                            {showAutoconso && <td>{currencyFormat(element.autoconso)}</td>}
                                            {showRevente && <td>{currencyFormat(element.revente)}</td>}
                                            <td>{currencyFormat(element.economieHorsPV)}</td>
                                            <td>{currencyFormat(element.total)}</td>
                                            <td>{percentFormat(element.rendement, 2)}</td>
                                        </tr>
                                    ))}
                                    <tr className="total">
                                        {contentFooter &&
                                            contentFooter.map((footer, index: number) => (
                                                <td colSpan={footer.colspan ? +footer.colspan : 0} key={index}>
                                                    {footer.title}
                                                </td>
                                            ))}
                                    </tr>
                                </tbody>
                            </table>
                        )}

                        {selectedTab === 1 && (
                            <div className="row" style={{ height: '500px' }}>
                                <div className="col-12 col-md-8">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart data={prepareChartData(annuel)} margin={{ left: 45, right: 10 }}>
                                            <CartesianGrid strokeDasharray="3 3" />

                                            {/* Axe X pour l'année */}
                                            <XAxis dataKey="annee" type="number" domain={[0, 25]} ticks={Array.from({ length: 26 }, (_, i) => i)} />

                                            {/* Axe Y à gauche pour le Gain cumulé (en euros) */}
                                            <YAxis
                                                yAxisId="left"
                                                label={{ value: 'Gain cumulé', angle: -90, position: 'center', dx: -60 }}
                                                tickFormatter={(value: number) => `${currencyFormat(value)}`}
                                            />

                                            {/* Afficher les informations au survol */}
                                            <Tooltip
                                                content={<ProjectionFinanciereCustomTooltip />}
                                                cursor={{ fill: 'rgba(225, 225, 225, 0.5)' }}
                                                wrapperStyle={{ outline: 'none' }}
                                            />

                                            {/* Ligne bleue pour le Gain cumulé */}
                                            <Line yAxisId="left" type="monotone" dataKey="gainCumule" name="Gain cumulé" stroke="#349f4a" dot={false} />

                                            {/* Ligne turquoise horizontale pour le Coût total de l'installation */}
                                            <ReferenceLine y={Math.round(total)} yAxisId="left" stroke="#f58920" />

                                            {/* Zone de retour sur investissement */}
                                            <ReferenceArea x1={startYear} x2={endYear} fill="#76ca4f" opacity={0.5} yAxisId="left" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>

                                <div className="col-12 col-md-4">
                                    <div className="modal-content-list" style={{ maxHeight: '470px' }}>
                                        <h2>Légende</h2>

                                        <ul>
                                            <li>
                                                <p data-key={'orange'}>
                                                    <span>Coût total de l'installation :</span> {currencyFormat(total)}
                                                </p>
                                            </li>
                                            <li>
                                                <p data-key={'green'}>
                                                    <span>Gain cumulé :</span> {currencyFormat(content.economieHorsPV.prix())}
                                                </p>
                                            </li>
                                            <li>
                                                <p data-key={'secondary'}>
                                                    <span>Retour sur investissement :</span> dans la {handleSuffixYear(endYear)} année
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModaleProjectionFinanciere;
